<template>
  <div class="container has-background-white-ter">




    <textarea v-model="myData"></textarea>
    <button @click="process()">Process</button>
    <ul>
      <li v-for="(d, i) in details" :key="i">
        {{i }} - {{ d }}
      </li>
    </ul>

  </div>
</template>

<script>


export default {
  name: "ia",



  data: function () {
    return {

      myData: "",

      details: ["ready"],
    };
  },
  methods: {

    process() {


      const splitLines = this.myData.split(/\r?\n/).filter(x=>x);

      const re = /^[ \t]*(\d+)[ \t]*,.*\((\d+)\).*[ \t]*,ACTIVE.*(https:\/\/classroom[^,]+)/

      splitLines.forEach(x => {


        const ok = re.exec(x);

        if (!ok) {
          return;
        }
        console.log([x, ok])
        this.details.push([ok[2], ok[3]])



        this.$api.post("v2/staff/group/"+ok[2], true, true, { urlClassroom: ok[3] });


      })



    }

  },

};
</script>
